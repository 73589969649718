<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton v-if="caption == 'Ventas'" color="info" size="sm" @click="newSale()">Nueva Venta</CButton>
          <CButton v-if="caption == 'Apartados'" color="info" size="sm" @click="newPreSale()">Nuevo Apartado</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #id="{item}"><td class="w100 text-center">{{item.id}}</td></template>
        <template #date="{item}"><td class="w200 text-center">{{item.date | date}}</td></template>
        <template #payment_form="{item}"><td class="w200 text-center">{{item.payment_form == "cash" ? "Efectivo" : (item.payment_form == "debit" ? "Tarjeta de Débito" : (item.payment_form == "credit" ? "Tarjeta de Crédito" : "Regalo"))}}</td></template>
        <template #subtotal="{item}"><td class="w100 text-right">$ {{item.subtotal | currency}}</td></template>
        <template #discount="{item}"><td class="w100 text-right">$ {{item.discount | currency}}</td></template>
        <template #total="{item}"><td class="w100 text-right">$ {{item.total | currency}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="info" size="sm" @click="showPreSale(item.id)"><CIcon name="cil-zoom"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/sales';
import store from '../../store'

export default {
  name: 'SalesTable',
  components: { },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: '#'},
          {key: 'date', label: 'Fecha'},
          {key: 'customer_name', label: 'Cliente'},
          {key: 'payment_form', label: 'Forma de Pago'},
          {key: 'subtotal', label: 'Subtotal'},
          {key: 'discount', label: 'Descuentos'},
          {key: 'total', label: 'Total'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    newSale () {
      window.location.href = "/#/sale";
    },
    newPreSale () {
      window.location.href = "/#/presale";
    },
    showSale (sale_id) {
      window.location.href = "/#/sales/"+sale_id+"/view";
    },
    showPreSale (sale_id) {
      window.location.href = "/#/presales/"+sale_id+"/view";
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Categoría", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
