<template>
  <div>
    <SalesTable
      :items="sales"
      hover
      striped
      border
      small
      fixed
      caption="Ventas"
      @refresh="refreshItems"
    />
  </div>
</template>
<script>

import SalesTable from '../../components/sales/SalesTable.vue'
import sales from '../../services/sales';

import store from '../../store'

export default {
  name: 'Sales',
  components: {
    SalesTable
  },
  data: function () {
		return {
            sales: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await sales.get();

    if(response.type == "success"){
      this.sales = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.sales = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
